.nav-menus {
  li {
    #Stroke-3 {
      stroke: var(--primary-icon-color);
    }
    #Path_876,
    #Path_877,
    #Path_878 {
      fill: var(--primary-icon-color);
    }

    &:hover {
      #Path_876,
      #Path_877,
      #Path_878 {
        fill: var(--primary-hover-active-color);
      }
      #Path_942{
        fill:var(--primary-bg-color);
      }
      #Stroke-3 {
        stroke: var(--primary-hover-active-color);
      }
    }
  }

  li.active {
    #Path_876,
    #Path_877,
    #Path_878 {
      fill: var(--primary-hover-active-color);
    }
    #Stroke-3 {
      stroke: var(--primary-hover-active-color);
    }
  }
}

li {
  #Path_750,
  #phone {
    fill: var(--secondary-text-icon-color);
  }
  #Stroke-6 {
    stroke: var(--secondary-text-icon-color);
  }
  a:hover {
    #Path_958
     {
      fill: var(--secondary-hover-active);
    }

    #Stroke-6 {
      stroke: var(--secondary-hover-active);
    }
  }
  a.active {
    #list {
      fill: var(--secondary-hover-active);
    }
    #Stroke-6 {
      stroke: var(--secondary-hover-active);
    }
  }
  a{
    #Layer_1,#requirement{
      fill:var(--secondary-text-icon-color);
    }
  }
}

li{
  #Path_1044
  {
    fill: var(--secondary-text-icon-color);
  }
  #path1296
  {
    stroke: var(--secondary-text-icon-color);
  }
  #Path_1045
  {
    fill: var(--secondary-text-icon-color);
    stroke: var(--secondary-text-icon-color);
  }
  a{
    &:hover
    {
      #Path_1044
      {
        fill: var(--secondary-hover-active);
      }
      #path1342
      {
        stroke: var(--secondary-hover-active);
      }
      #Path_1051
      {
        fill:    var(--secondary-hover-active);
        stroke:  var(--secondary-hover-active);
      }
    }
  }
  a.active
  {
    #Path_1044
      {
        fill: var(--secondary-hover-active);
      }
    #path1342
      {
      stroke: var(--secondary-hover-active);
      }
      #Path_1051
      {
        fill:    var(--secondary-hover-active);
        stroke:  var(--secondary-hover-active);
      }
  }
}