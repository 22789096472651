/* You can add global styles to this file, and also import other style files */
@import "./assets/scss/app.scss";
@import "../src/assets/variable.scss";
$font-Poppins: "Poppins";
@import "ag-grid-community/styles/ag-grid.css";
@import "ag-grid-community/styles/ag-theme-alpine.css";
@import "./assets/scss/themIconColor/iconColor.scss";
@import "../src/assets/scss//themeButton/button.scss";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap");

html {
  font-family: "Poppins", sans-serif;
}
* {
  font-family: "Poppins";
  letter-spacing: 0;
}

.router-wrapper {
  position: relative;
  overflow: hidden;
  width: 100vw;
  height: calc(100% - 47px);
  perspective: 1200px;
  transform-style: preserve-3d;
}
// layout css
.page-wrapper.compact-wrapper {
  .page-header {
    margin-left: 0;
    width: 100%;
    border-bottom: 4px solid var(--secondary-accent-line-color);
    .header-wrapper {
      padding: 0;
    }
    &.close_icon {
      margin-left: 0;
      width: 100%;
    }
  }
  .page-body-wrapper .page-body {
    background-color: #ffffff;
    margin-left: 0;
    padding-left: 0;
    padding-right: 0;
  }
  .footer {
    width: 100%;
    margin-left: 0;
    z-index: 3;
  }
}
// layout css

:host {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: #60d7a9;
}

.page-body {
  background-color: var(--primary-colors);
}
.hand{
  cursor: pointer;
}
.list-manager-info{
  .ag-theme-alpine {
    .ag-root-wrapper {
      .ag-header { 
        border-bottom: 1px solid var(--sitewide_primary_accent_color) !important;
        background-color: transparent !important;
      }
      .ag-row-even {
        background-color: #FFFFFF;
        --ag-selected-row-background-color: #FFFFFF !important;
      }
      .ag-row-odd {
        background-color: #FFFFFF;
        --ag-selected-row-background-color: #FFFFFF !important;
      }
      .ag-row {
        // border-bottom: 1px solid #8B9DAA !important; 
         border-bottom: 1px solid rgba(139, 157, 170, 0.2) !important;
         .ag-cell{
          p{
            margin: 9px 0px 9px 0px;
          }
          .ag-cell-value {
            color: #646E76;
          }
    
         }
    }
    }
  }
}
//ag-grid
.ag-theme-alpine {
  .ag-root-wrapper {
    border: none !important;
    .ag-pinned-left-cols-container {
      position: initial;
    }
    .ag-center-cols-container {
      //   left: -1px;
    }
    .ag-center-cols-clipper {
      .ag-center-cols-viewport {
        height: calc(100% + 7px) !important;
        .ag-center-cols-container {
          //  left: -3px;
        }
      }
    }
    .ag-header {
      border-bottom: none !important;
      background-color: #e9edf2 !important;
      .ag-header-cell {
        font-weight: normal;
        font-size: 13px;
        color: var(--sitewide_primary_accent_color);
        .ag-header-select-all {
          margin-right: 0;
          padding-right: 18px;
        }
        .ag-checkbox-input-wrapper:not(:checked)::after {
          border-color: var(--sitewide_primary_accent_color);
        }
        .ag-checkbox-input-wrapper.ag-checked::after {
          border-color: white;
        }
      }
    }
  }
  .ag-center-cols-viewport {
    // overflow-x: hidden;
  }
  .ag-body-horizontal-scroll {
    height: 6px !important;
    max-height: 6px !important;
    min-height: 6px !important;
    .ag-body-horizontal-scroll-viewport {
      height: 8px !important;
      max-height: 6px !important;
      min-height: 6px !important;
    }
  }

  .ag-checkbox-input {
    border-radius: 1px;
    cursor: pointer !important;
  }
  .ag-checkbox-input-wrapper {
    font-family: var(--ag-icon-font-family);
    font-size: var(--ag-icon-size);
    line-height: var(--ag-icon-size);
    font-style: normal;
    font-weight: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 18px;
    border: none;
    height: 18px;
    background-color: red !important;
    border-radius: 5px;
    display: inline-block;
    vertical-align: middle;
    flex: none;
    position: relative;
    top: -1px;
    cursor: pointer;
  }
  .ag-header-select-all {
    .ag-checkbox-input-wrapper {
      position: relative;
      top: 1px;
      box-shadow: none;
    }
  }
  .ag-checkbox-input-wrapper::after {
    color: transparent;
    position: absolute;
    top: -1px;
    left: 0;
    pointer-events: none;
    width: 19.2px;
    height: 19.2px;
    border-radius: 5px;
    left: 0px;
    border: 1.3px solid $text-light-gray;
    background-color: transparent;
  }
  .ag-checkbox-input-wrapper {
    background-color: var(--sitewide_accent_color) !important;
    box-shadow: none;
    &.ag-checked::after {
      position: absolute;
      content: "";
      border-radius: 5px;
      transform: rotate(45deg) scale(1);
      left: 7px;
      top: 3px;
      width: 4px;
      height: 10px;
      border: solid #fff;
      border-width: 0 2px 2px 0;
      border-radius: 0;
      background: transparent;
    }
  }
  .ag-checkbox-input-wrapper.ag-checked {
    background-color: var(--sitewide_primary_accent_color) !important;
  }

  .ag-checkbox-input-wrapper.ag-indeterminate::after {
    position: absolute;
    content: "";
  }

  .ag-row {
    border: 1px solid transparent !important;
    .ag-cell-focus:not(.ag-cell-range-selected):focus-within {
      border-color: transparent !important;
    }
    .ag-cell {
      font-size: $font-13;
      border-right-width: 2px !important;
      .ag-selection-checkbox {
        margin-right: 0;
        padding-right: 18px;
      }
      &:focus {
        border: 1px solid transparent !important;
        box-sizing: border-box;
      }
      .ag-cell-value {
        padding-left: 18px;
        color: var(--sitewide_primary_accent_color);
      }
    }
    .ag-cell-inline-editing {
      height: auto;
      background-color: transparent;
      box-shadow: none !important;
      border-color: #ffffff !important;
    }
    .ag-cell.padding-left-38px {
      // padding: 0 0 0 43px;
      padding: 0 0 0 48px;
      color: $text-gray;
    }
    .ag-cell.padding-left-45px {
      padding: 0 0 0 45px;
    }
  }
  .ag-row-selected {
    border: 1px solid var(--sitewide_primary_accent_color) !important;
    background-color: #ffffff !important;
    .ag-cell-focus:not(.ag-cell-range-selected):focus-within {
      border-color: transparent !important;
    }
    .ag-cell {
      background-color: transparent !important;
      &:focus {
        border: 1px solid transparent !important;
        box-sizing: border-box;
      }
      span {
        position: relative;
      }
    }
  }
  .ag-row-even {
    background-color: #ffffff;
    --ag-selected-row-background-color: #ffffff !important;
  }
  .ag-row-odd {
    background-color: #fafafa;
    --ag-selected-row-background-color: #fafafa !important;
  }

  .ag-cell.ag-cell-last-left-pinned:not(.ag-cell-range-right):not(
      .ag-cell-range-single-cell
    ) {
    border: none;
  }
  .ag-pinned-left-header {
    border: none;
  }

  .ag-horizontal-left-spacer {
    border: none !important;
  }
}
.ag-row-hover:not(.ag-full-width-row)::before,
.ag-row-hover.ag-full-width-row.ag-row-group::before {
  background-color: #fff !important;
}
// Pagination

.pagination-control {
  .pagination {
    // justify-content: center !important;
    .page-item {
      .page-link {
        padding: 2px 8px !important;
        color: $text-light-gray !important;
        border: 1px solid $semi-info !important;
        border-color: $semi-info !important;
        font-size: $font-11;
      }
    }

    .page-item.active {
      .page-link {
        background-color: #fff !important;
        border-color: $semi-info !important;
        color: var(--sitewide_primary_accent_color) !important;
        font-size: $font-11;
      }
    }
  }
}
  //profile icon
  app-my-account {
    .profilewidth {
      width: 174px;
    }
    .onhover-show-div {
      left: -8.4rem;
      top: 38px;
      height: 83px !important;
    }
    li {
      padding-left: 10px;
      margin-top: 12px;
  
      a {
        span {
          padding-left: 10px;
          font-size: 12px;
        }
      }
    }
    app-feather-icons {
      padding-left: 10px;
      svg {
        height: 18px;
        width: 18px;
        vertical-align: middle;
      }
    }
  }

  // color classes

.text-primary-color {
  color: var(--sitewide_primary_accent_color);
}
.text-semi-primary-color {
  color: $semi-primary;
}
.text-light-info {
  color: $light-info !important;
}
.text-gray {
  color: $text-gray !important;
  // font-weight: bold;
}
.text-light {
  color: $text-light;
}
.text-semi-light {
  color: $semi-light;
}
.text-light-gray {
  color: $text-light-gray !important;
}
.text-semiPrimary-color {
  color: $semi-primary;
}

.text-secondary {
  color: $secondary-color !important;
}

.text-info-color {
  color: $text-info !important;
  span{
      color: var(--sitewide_primary_accent_color);
      cursor: pointer;
  }
}
.text-danger-color{
  color: $text-danger;
}
// color classes

// background-color classes
.bg-semi-light {
  background-color: $semi-light;
}
.bg-primary-color {
  background-color: var(--sitewide_primary_accent_color);
}

.bg-light-color {
  background-color: $text-light;
}
// background-color classes

//font-size
.font-15 {
  font-size: $font-15;
}
.font-16 {
  font-size: $font-16;
}
.font-12 {
  font-size: $font-12;
}
.font-13 {
  font-size: $font-13;
}
.font-11 {
  font-size: $font-11;
}
.font-10 {
  font-size: $font-10;
}
//

  select:focus {
    outline: none; 
    border-color:rgba(194,219,254,255); 
    box-shadow: 0 0 5px rgba(194,219,254,255);
    box-shadow: 0 0 0 0.15rem rgba(194,219,254,255);

  }
    

 // start remove focus for checkbox and switch
.form-check-input:focus {
  outline: none;
  box-shadow: none;
}
// end remove focus
   

   .modal-content {
     border-radius: 20px;
     .modal-header{
      padding: 1.8rem 1.8rem 0 1.8rem;
     }
     .border-0 {
      border: 1px solid transparent !important;
    }
    .modal-title {
      font-weight: 300;
    }
     .modal-body{
      padding: 1.8rem;
     }
     .modal-footer{
      padding: 0 1.8rem 1.8rem 1.8rem;
     }
    
   }

  .editMailinglist {
    .modal-dialog {
      max-width: 385px;
    }
  }


  .border-red {
    .form-floating {
      .form-control,
      .form-control:focus,
      .form-control:not(:placeholder-shown),
      .form-select {
        border: 1px solid red !important;
      }
    }
    .wrap_Multiselect-div {
      border: 1px solid red !important;
    }
  }
  
  //floatin label css for input and dropdown manthan
  .form-floating {
    .form-control,
    .form-control:focus,
    .form-control:not(:placeholder-shown),
    .form-select {
      padding: 1rem 2.7rem 0rem 2rem;
      color: $primary-color !important;
      height: calc(2.7rem + 2px);
      min-height:  calc(2.7rem + 2px);
      line-height: -1;
      border-radius: 10px !important;
      border: 1px solid $semi-info;
      box-shadow: none !important;
      font-size: 12px;
    }
    .form-control:not(:placeholder-shown) label,
    .form-select label,
    .form-control:focus label,
    .form-select:focus label {
      opacity: 1 !important;
    }
    span {
      padding: 1.2em 0.8em;
      .svgIcon {
        svg {
          stroke: $primary-color;
        }
      }
    }
    label {
      padding: 0.7rem 2.25rem;
      opacity: 1 !important;
      color: $text-light-gray !important;
      font-size:11px;
      transition: all 400ms ease-in-out !important;
      overflow: visible;
    }
    i {
      color: black;
      padding: 0.71rem;
    }
  }
  
  //end
  
  //common search input
  // search input :start
  $color_inputSearch: $secondary-color;
  .input_searchContainer {
    border-bottom: 1px solid $text-light-gray;
    .searchIcon {
      padding: 0.3rem;
      svg #Path_861{
        fill: var(--sitewide_primary_accent_color) !important;
        stroke: var(--sitewide_primary_accent_color) !important;
      }
    }
    .search_input {
      border: none;
      color: var(--sitewide_primary_accent_color);
      outline: none;
      width: -webkit-fill-available;
      font-size: $font-13;
    }
    .search_input::placeholder {
      color: var(--sitewide_primary_accent_color);
    }
    .clearSearchInput {
      display: none;
      right: 1%;
      cursor: pointer !important;
      svg {
        fill: var(--sitewide_primary_accent_color);
        stroke: var(--sitewide_primary_accent_color);
      }
    }
  }
  //// :end
  
  //common popup width
.deletePop {
  .modal-dialog {
    max-width: 500px;
  }
}